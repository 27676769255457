import React from "react";
import "./services.style.css";
export default function Services() {
  return (
    <div className="main" id="services">
      <h1>Services</h1>

      <div className="service-container">
        <div className="text-container">
          <p>
            Our main goal is to provide transportation in a friendly and
            professional environment. When you're traveling with us, we are
            committed that you travel stress-free. Sit back and relax, and let
            us take care of the rest.
            <h3 style={{ textAlign: "center", paddingTop: "10px" }}>
              List of services we provide
            </h3>
            {/* Serving Seattle since 2018 with consistent 5 star ratings" Good
            customer service, friendly! Services Offered */}
          </p>
        </div>
        <div className="service-container">
          <div className="services-list">
            {/* ==================================================== */}
            <div className="bullets">
              <span className="bullets-icon">✓</span>
              <span className="bullets-text">Private Ride</span>
            </div>
            {/* ==================================================== */}
            <div className="bullets">
              <span className="bullets-icon">✓</span>
              <span className="bullets-text">Weddings</span>
            </div>
            {/* ==================================================== */}
            <div className="bullets">
              <span className="bullets-icon">✓</span>
              <span className="bullets-text"> Special events</span>
            </div>
            {/* ==================================================== */}
            <div className="bullets">
              <span className="bullets-icon">✓</span>
              <span className="bullets-text">work</span>
            </div>
            {/* ==================================================== */}
            <div className="bullets">
              <span className="bullets-icon">✓</span>
              <span className="bullets-text">cruise lines</span>
            </div>
            {/* ==================================================== */}
            {/* ==================================================== */}
            <div className="bullets">
              <span className="bullets-icon">✓</span>
              <span className="bullets-text">airport</span>
            </div>
            {/* ==================================================== */}
          </div>
        </div>
      </div>
    </div>
  );
}
