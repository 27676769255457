import React, { useState } from "react";
import "./top-nav.style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-scroll";
export default function TopNav() {
  const [checked, setChecked] = useState(false);
  return (
    <div className="top-nav">
      <div className="logo">
        <h3 style={{ width: "100%" }}>Danny Seattle Town Car Service</h3>
      </div>
      <label htmlFor="humberger" className="checkbtn">
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faBars}
        />
      </label>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(!checked);
        }}
        id="humberger"
      />

      <ul className="nav">
        <li className="nav-list">
          <Link
            activeClass="active nav-link"
            spy={true}
            to="home"
            onClick={(e) => {
              setChecked(false);
            }}
            offset={-70}
            className="nav-link"
          >
            Home
          </Link>
        </li>

        <li className="nav-list">
          <Link
            activeClass="active nav-link"
            spy={true}
            to="services"
            onClick={(e) => {
              setChecked(false);
            }}
            offset={-70}
            className="nav-link"
          >
            Services
          </Link>
        </li>
        <li className="nav-list">
          <Link
            activeClass="active nav-link"
            spy={true}
            onClick={(e) => {
              setChecked(false);
            }}
            to="about-us"
            offset={-70}
            className="nav-link"
          >
            About
          </Link>
        </li>
        <li className="nav-list">
          {/*  contact-us */}
          <Link
            activeClass="active nav-link"
            spy={true}
            onClick={(e) => {
              setChecked(false);
            }}
            to="contact-us"
            offset={-70}
            className="nav-link"
          >
            Contact
          </Link>
        </li>
        <li className="nav-list"></li>
        {/* <li className="nav-list">
          <a href="#">login</a>
        </li>
        <li className="nav-list">
          <a href="#">Sign up</a>
        </li> */}
      </ul>
    </div>
  );
}
