import "./App.css";
import { Routes, Route } from "react-router-dom";
import TopHero from "./layout/nav/hero/top-hero";

import TopNav from "./layout/nav/top-nav/top-nav";
import Footer from "./layout/footer/footer";

import Home from "./views/home/home";
import About from "./views/about/about";
import ContactUs from "./views/contact-us/contact-us";
import Services from "./views/services/services";

function App() {
  return (
    <div className="App">
      {/* <TopHero /> */}
      <TopNav />
      <Home />
      <Services />
      <About />

      {/* <ContactUs /> */}
      <Footer />
      {/* <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer /> */}
      {/* <Button name="Accept" /> */}
    </div>
  );
}

export default App;
