import React from "react";
import "./home.style.css";
import internal from "../../assets/images/new-interior.jpeg";
import external from "../../assets/images/new.jpeg";
import img1 from "../../assets/images/new_black.jpeg";
import img2 from "../../assets/images/IMG_2463.JPG";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMessage } from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  return (
    <div className="main home" id="home">
      <div className="content">
        <div className="content-padding">
          <div className="content-desc">
            <h1>Well Come To Seatle Car Service</h1>
            <div className="content-desc-icons" style={{ paddingTop: "20px" }}>
              <a href="tel:+19298551632">
                <span
                  style={{
                    // marginRight: "40px",
                    display: "inline-block",
                    fontSize: "1.5rem",
                    background: "#00f800",
                    padding: "20px",
                  }}
                >
                  <FontAwesomeIcon
                    data-background="lime"
                    className="icons"
                    icon={faPhone}
                  />
                </span>
              </a>
              <a href="sms:+19298551632">
                <span
                  style={{
                    // marginRight: "40px",
                    display: "inline-block",
                    fontSize: "1.5rem",
                    background: "#0070F8",

                    padding: "20px",
                  }}
                >
                  <FontAwesomeIcon
                    data-background="lime"
                    className="icons"
                    icon={faMessage}
                  />
                </span>
              </a>

              {/* <span
                style={{
                  padding: "20px",
                  display: "inline-block",
                  fontSize: "1.5rem",
                  color: "#00f800",
                }}
              >
                <a href="sms:+19298551632">
                  <FontAwesomeIcon
                    data-background="lime"
                    className="icons"
                    icon={faMessage}
                  />
                </a>
              </span> */}
              <h1 style={{ margin: "20px auto" }}>
                <a href="tel:+19298551632">+19298551632</a>
              </h1>
            </div>
          </div>
        </div>

        {/* <div className="goy-home-idea">
          <h1> Well Come To Seatle Car Service</h1>
        </div> */}
      </div>

      <div id="slider">
        <figure>
          <img src={external} alt="" srcset="" />
          <img src={img2} alt="" srcset="" />
          <img src={internal} alt="" srcset="" />
          <img src={img1} alt="" srcset="" />
          <img src={external} alt="" srcset="" />
          {/* <img src={internal} alt="" srcset="" /> */}
        </figure>
      </div>
    </div>
  );
}
