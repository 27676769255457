import React from "react";
import "./footer.style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTelegram,
  faTwitter,
  faInstagram,
  faLinkedin,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
export default function Footer() {
  console.log("hello seatle");
  return (
    // target="_blank" rel="noopener noreferrer"

    <footer className="footer" id="contact-us">
      <a href="#" className="social-icons" target="_blank">
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faFacebook}
        />
        <span className="tex">facebook</span>
      </a>
      <a href="#" className="social-icons" target="_blank">
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faTwitter}
        />
        <span className="tex">twitter</span>
      </a>
      <a
        href="https://instagram.com/danny_seattle_car_service?igshid=YWJhMjlhZTc="
        target="_blank"
        className="social-icons"
      >
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faInstagram}
        />
        <span className="tex">Instagram</span>
      </a>
      <a href="#" className="social-icons" target="_blank">
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faLinkedin}
        />
        <span className="tex">Linkedin</span>
      </a>
      <a href="#" className="social-icons" target="_blank">
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faTelegram}
        />
        <span className="tex">Telegram</span>
      </a>
      <a href="#" className="social-icons" target="_blank">
        <FontAwesomeIcon
          data-background="lime"
          className="icons"
          icon={faTiktok}
        />
        <span className="tex">Tiktok</span>
      </a>
    </footer>
  );
}
