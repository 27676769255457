import React from "react";
import "./about.style.css";
import img1 from "../../assets/images/IMG_2470.JPG";
import img2 from "../../assets/images/IMG_2467.JPG";
export default function About() {
  return (
    <div className="main" id="about-us">
      <h1>About Page</h1>
      <div className="about-main-content">
        <div className="about-content">
          <img src={img2} alt="Dani" style={{ Width: "350px" }} />
          <p>
            My name is Danny I was born and raise in Ethiopia . My dream job was
            to became a professional car driver. I have received my first driver
            license at the age of 16 in Ethiopia. At the age of 18 I became a
            professional car driver. For the last seven years I have worked for
            different company’s including Uber, Lyft, Grabhub , and Amazon. I
            have Five start rate in Uber and Lyft. I love helping people to get
            to there destination safely and comfortably. I always insure my
            riders to enjoy the ride with lovely music and good conversations.
          </p>
        </div>
        <div className="about-content">
          <img src={img1} alt="Dani" />
          <p></p>
        </div>
      </div>
    </div>
  );
}
